<template>
  <v-container>
    <v-row v-if="user">
      <v-col class="d-flex flex-wrap align-center">
        <div>
          <div v-if="booking" class="my-2 font-weight-medium">
            {{ $trans("booking_id") }}: {{ booking.id }}
          </div>
          <div v-if="userSubscription" class="my-2 font-weight-medium">
            {{ $trans("user_subscription_id") }}: {{ userSubscription.id }}
          </div>
          <div class="pointer" @click="goToUser(user)">
            <avatar-user-names :user="user" />
          </div>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <user-payments
          :force-mode="paymentMode"
          :booking="booking"
          :user-subscription="userSubscription"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import UserPayments from "@/views/dashboard/pages/Users/components/UserPayments";
import AvatarUserNames from "@/components/AvatarUserNames";
import roleActions from "@/calendesk/mixins/roleActions";

export default {
  name: "UserPaymentTransactionsDialog",
  components: { AvatarUserNames, UserPayments },
  mixins: [roleActions],
  data() {
    return {
      user: null,
      paymentMode: null,
      booking: null,
      userSubscription: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedUser: "user/getSelectedUser",
    }),
  },
  created() {
    this.user = this.getDialog.data.user;
    this.paymentMode = this.getDialog.data.paymentMode;

    if (
      this.getDialog.data.paymentMode ===
      this.$helpers.userPaymentTypeMode.BOOKINGS
    ) {
      this.booking = this.getDialog.data.booking;
    } else if (
      this.getDialog.data.paymentMode ===
      this.$helpers.userPaymentTypeMode.USER_SUBSCRIPTIONS
    ) {
      this.userSubscription = this.getDialog.data.userSubscription;
    }

    this.setSelectedUser(this.user);
  },
  methods: {
    ...mapMutations({
      setSelectedUser: "user/SET_SELECTED_USER",
    }),
    goToUser(user) {
      if (this.hasPermission(this.employeePermissions.SHOW_USERS)) {
        this.closeDialog();
        this.$router.push({ name: "user", params: { id: user.id } });
      }
    },
  },
};
</script>
